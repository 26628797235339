import { colorBackgroundLayoutMain } from "@amzn/awsui-design-tokens";
import { decode, encode } from "base64-arraybuffer";
import { LoginOption } from "components/login/internal/LoginSelector";
// converts a string to an array buffer
// used to convert U2F challenge data to the format expected by WebAuthN API
import * as Cookies from "js-cookie";
import { CSSProperties } from "react";

import { androidAeaApp } from "./android_aea_app";
import { iOSAeaApp } from "./ios_aea_app";

export function str2ab(enc: string) {
  return decode(enc.replace(/_/g, "/").replace(/-/g, "+"));
}

// converts an array buffer to a base64 urlsafe string
// used to convert webauthn response from the token to send over the wire
export function ab2str(buf: ArrayBuffer): string {
  return encode(buf).replace(/\//g, "_").replace(/\+/g, "-").replace(/=*$/, "");
}

export function switchToOldUI() {
  Cookies.set("ui-preference", "oldui", { expires: 365 });
  // remove ui-preference parameter from url if present
  const url = new URL(window.location.href);
  if (url.searchParams.has("ui-preference")) {
    url.searchParams.delete("ui-preference");
    window.location.href = url.toString();
  } else {
    window.location.reload();
  }
}

export function switchToNewUI() {
  Cookies.set("ui-preference", "newui", { expires: 365 });
  // remove ui-preference parameter from url if present
  const url = new URL(window.location.href);
  if (url.searchParams.has("ui-preference")) {
    url.searchParams.delete("ui-preference");
    window.location.href = url.toString();
  } else {
    window.location.reload();
  }
}

export function setDoNotUsePIN(set: boolean) {
  Cookies.set("do_not_use_pin", set ? "1" : "0", { expires: 1 / 48 });
}

export function doNotUsePIN(): boolean {
  return Cookies.get("do_not_use_pin") == "1";
}

export function setTheme() {
  document.body.classList.add("awsui-visual-refresh");
  const useDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
  if (useDarkMode) {
    document.body.classList.add("awsui-polaris-dark-mode");
    document.body.style.background = colorBackgroundLayoutMain;
  } else if (!useDarkMode) {
    document.body.classList.remove("awsui-polaris-dark-mode");
  }
}

export interface ExtendedCSSProperties extends CSSProperties {
  WebkitTextSecurity?: string;
}

interface AuthenticatorResponseResult {
  userVerified: boolean;
}

/**
 * Parse the Authenticator Response from a PublicKeyCredential to check if the user is verified.
 *
 * @param publicKeyCredential - The PublicKeyCredential to parse.
 * @returns True if the user is verified; otherwise, false.
 */
export function parseAuthenticatorResponse(publicKeyCredential: PublicKeyCredential): AuthenticatorResponseResult {
  const authenticatorResponse = publicKeyCredential.response as AuthenticatorAssertionResponse;
  const authenticatorData = new DataView(authenticatorResponse.authenticatorData);
  const flags = authenticatorData.getUint8(32);
  const userVerified = (flags & 0x04) === 0x04;

  return { userVerified };
}

/**
 * Get the reg portal URL based on current partition
 */
export function getRegPortalURL() {
  const currentURL = window.location.href;
  if (currentURL.includes("https://midway-auth.amazon.com")) {
    return "https://midway.amazon.com";
  } else if (currentURL.includes("https://midway-auth-gamma.pdt.amazon.com")) {
    return "https://midway-gamma.pdt.amazon.com";
  } else if (currentURL.includes("https://midway-auth-itar.amazon.com")) {
    return "https://midway.pdt.amazon.com";
  } else if (currentURL.includes("https://midway-auth-gamma.bjs.aws-border.cn")) {
    return "https://midway-gamma.bjs.aws-border.cn";
  } else if (currentURL.includes("https://midway-auth.aws-border.cn")) {
    return "https://midway.aws-border.cn";
  } else if (currentURL.includes("https://midway-auth-gamma.lck.amazon.com")) {
    return "https://midway-gamma.lck.amazon.com";
  } else if (currentURL.includes("https://midway-auth.lck.aws-border.com")) {
    return "https://midway.lck.aws-border.com";
  } else if (currentURL.includes("https://midway-auth.sc2s.sgov.gov")) {
    return "https://midway.lck.aws-border.sgov.gov";
  } else if (currentURL.includes("https://midway-auth-gamma.sc2s.sgov.gov")) {
    return "https://midway-gamma.lck.aws-border.sgov.gov";
  } else if (currentURL.includes("https://midway-auth-integ.c2s-border.ic.gov")) {
    return "https://midway-gamma.dca.c2s-border.ic.gov";
  } else if (currentURL.includes("https://midway-auth.c2s.ic.gov")) {
    return "https://midway.dca.c2s-border.ic.gov";
  } else if (currentURL.includes("https://auth-integ.midway.csphome.adc-e.uk")) {
    return "https://register.auth-integ.midway.csphome.adc-e.uk";
  } else if (currentURL.includes("https://auth.midway.csphome.adc-e.uk")) {
    return "https://register.auth.midway.csphome.adc-e.uk";
  } else if (currentURL.includes("https://auth-integ.midway.csphome.hci.ic.gov")) {
    return "https://register.auth-integ.midway.csphome.hci.ic.gov";
  } else if (currentURL.includes("https://auth.midway.csphome.hci.ic.gov")) {
    return "https://register.auth.midway.csphome.hci.ic.gov";
  } else {
    return "https://midway-gamma.amazon.com";
  }
}

export function getDefaultLoginOption(): LoginOption {
  if (isIOSBiometricAvailable()) {
    return "ios_biometric";
  } else if (isAndroidBiometricAvailable()) {
    return "android_biometric";
  } else {
    return "security_key";
  }
}

export function isIOSBiometricAvailable() {
  return iOSAeaApp.isApiAvailable();
}

export function isAndroidBiometricAvailable() {
  return androidAeaApp.isApiAvailable();
}

export function getBrowserFromUserAgent() {
  // extracted from Elasticsearch by searching for the top 100 navigator.userAgents and filtered out those from integration
  // tests, scanning services, or headless browsers
  if (!navigator.userAgent.includes("Mozilla/")) return "Unknown";
  const nonHumanKeywords = [
    "DynamicScanningService",
    "Cypress",
    "awsresearcher",
    "DyePack",
    "DynamicScanningFramework",
    "dyepack",
    "HeadlessChrome",
    "tpsgenerator",
    "CloudWatchSynthetics",
  ];
  for (const keyword of nonHumanKeywords) {
    if (navigator.userAgent.includes(keyword)) return "Unknown";
  }
  if (navigator.userAgent.includes("AEAMobile")) return "AEAMobile";
  // refer to https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator for browser detection using navigator.userAgent
  else if (navigator.userAgent.includes("Firefox")) return "Firefox";
  else if (navigator.userAgent.includes("SamsungBrowser")) return "OtherBrowser";
  else if (navigator.userAgent.includes("Opera") || navigator.userAgent.includes("OPR")) return "OtherBrowser";
  else if (navigator.userAgent.includes("Edg") || navigator.userAgent.includes("Edge")) return "Edge";
  else if (navigator.userAgent.includes("Chrome")) return "Chrome";
  else if (navigator.userAgent.includes("Safari")) return "Safari";
  else return "Unknown";
}

export function getOSFromUserAgent() {
  if (navigator.userAgent.includes("Android") || navigator.userAgent.includes("android")) return "Android";
  else if (
    navigator.userAgent.includes("Mobile") ||
    navigator.userAgent.includes("AmazonEnterpriseAccess") ||
    navigator.userAgent.includes("AEAMobile; iOS") ||
    navigator.userAgent.includes("AEAMobile/1.0.ios")
  )
    return "iOS";
  else if (navigator.userAgent.includes("Windows") || navigator.platform.includes("Win")) return "Windows";
  else if (navigator.userAgent.includes("Linux") || navigator.platform.includes("Linux")) return "Linux";
  else if (navigator.userAgent.includes("Mac") || navigator.platform.includes("Mac")) return "Mac";
  else return "OtherOS";
}
